.container-bg {
    background-image: url('../images/cube-bg.svg');
    background-repeat: no-repeat;
}

.bg-card-semi-opaque {
    background-color: rgba(225, 255, 254, 0.675);
}
.bg-card-white-opaque {
    background-color: rgba(255, 255, 255, 0.7);
}